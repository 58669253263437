/* Popup Overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Popup Content */
.popup-content {
    background: white;
    padding: 25px;
    border-radius: 12px;
    width: 80%;
  ;
    height: auto;
    max-height: 95vh;
    overflow-y: auto;
    text-align: center;
    position: relative;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Close Button */
.close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 22px;
    cursor: pointer;
    background: none;
    border: none;
    color: #333;
    transition: 0.3s ease-in-out;
}

.close-btn:hover {
    color: #ff4d4d;
    transform: scale(1.1);
}

/* Responsive Design */
@media (max-width: 768px) {
    .popup-content {
        width: 70%;
        max-width: 90%;
        height: auto;
        max-height: 90vh;
    }
}

@media (max-width: 480px) {
    .popup-content {
        width: 90%;
        padding: 20px;
    }

    .close-btn {
        top: 8px;
        right: 10px;
        font-size: 18px;
    }
}
