/* Wrapper to Center Form */
.custom-form-wrapper {
    text-align: center;
    padding: 20px;
}

/* Animated Form Heading */
.form-heading {
    font-size: 30px;
    font-weight: 700;
    color: #00b4d8;
    text-transform: uppercase;
    margin-bottom: 8px;
    letter-spacing: 1px;
    animation: fadeInDown 1s ease-in-out;
}

.form-heading span {
    color: #0098a3;
}

/* Subheading */
.form-subheading {
    font-size: 18px;
    color: rgba(7, 64, 13, 0.8);
    margin-bottom: 20px;
}

/* Main Form Container - Glassmorphism */
.custom-form-container {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(10px);
    max-width: 600px;
    margin: 20px auto;
    padding: 25px;
    border-radius: 18px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 0.3s ease-in-out;
}

/* Adjust Form Sections */
.custom-form-section {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
/* Input Field Wrapper */
.custom-input-box {
    position: relative;
    flex: 1;
}

/* Label Styling */
.custom-input-box label {
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    color: #219eb7;
    font-size: 15px;
}

.custom-input-box label svg {
    margin-right: 8px;
    color: #00b4d8;
}

/* Input, Select, Textarea Styling */
.custom-form-container input,
.custom-form-container select,
.custom-form-container textarea {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    background: rgba(255, 255, 255, 0.2);
    color: #111414;
    outline: none;
    transition: all 0.3s ease-in-out;
    box-shadow: inset 2px 2px 8px rgba(0, 0, 0, 0.1);
}

/* Focus Effect */
.custom-form-container input:focus,
.custom-form-container select:focus,
.custom-form-container textarea:focus {
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 0px 12px rgba(0, 180, 216, 0.3);
    border: 1px solid #00b4d8;
}

/* Placeholder Color */
.custom-form-container input::placeholder,
.custom-form-container textarea::placeholder {
    color: rgba(255, 255, 255, 0.7);
}

/* Submit Button */
.custom-submit-btn {
    width: 100%;
    padding: 14px;
    background: linear-gradient(135deg, #00fff2, #00a385);
    border: none;
    color: white;
    font-size: 18px;
    font-weight: 600;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* Submit Button Hover */
.custom-submit-btn:hover {
    background: linear-gradient(135deg, #0098a3, #00b4d8);
    transform: translateY(-2px);
    box-shadow: 0px 5px 15px rgba(0, 180, 216, 0.3);
}

.custom-submit-btn:disabled {
    background: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
}

/* Success & Error Messages */
.custom-success-msg, .custom-error-msg {
    font-weight: 600;
    text-align: center;
    margin-top: 15px;
    font-size: 15px;
}

.custom-success-msg {
    color: #28a745;
}

.custom-error-msg {
    color: #dc3545;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .custom-form-container {
        padding: 20px;
        width: 90%;
    }

    .form-heading {
        font-size: 26px;
    }

    .custom-input-box label {
        font-size: 14px;
    }

    .custom-form-container input {
        padding: 10px;
        font-size: 14px;
    }

    .custom-submit-btn {
        font-size: 16px;
        padding: 12px;
    }

    /* Ensure input fields are stacked on small screens */
    .custom-form-section {
        flex-direction: column;
    }
}

/* Fade-in Animation */
@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-15px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
