.scroll-to-top {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #2280de; /* Button color */
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 24px;
  transition: opacity 0.3s ease;
  z-index: 9999; /* Ensures the button appears above other elements */
}

.scroll-to-top:hover {
  background-color: #3e6a8d; /* Darker shade on hover */
}

.scroll-to-top span {
  display: inline-block;
  line-height: 0;
}
