/* .loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #f6f9fc;
    display: flex;
    justify-content: center;
    align-items: center;
} */


.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full height for centering */
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.loader {
    animation: pulse 1.5s infinite; /* Pulsing animation */
}

.loading-text {
    margin-top: 10px;
    font-size: 1.5rem;
    color: #14eeee; /* Match loader color */
    font-weight: bold;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
